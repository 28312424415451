// add customs styles and general overrides here
// due to the cascading nature of css, if you try to override theme css variables in this file, those changes will not apply. Instead, override css variables in the `override.sass` file
// we recommend not editing this file directly. Instead, create an `assets/sass/_custom.sass` file at the root level of your site.
// if you edit this file directly, you will have to resolve git conflicts when and if you decide to pull changes we make on the theme

.row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  gap: 10px;
  //grid-auto-flow: dense;
  //grid-auto-flow: column;
  //grid-auto-flow: row;
  //grid-auto-flow: column;
  //margin: 5px;
  padding: 0%;
  //grid-auto-flow: dense;
  //left-margin: auto;
  //right-margin: auto;
  //grid-auto-flow: column;
  //grid-auto-flow: row dense;
  //grid-auto-flow: column;
 }  //grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))

.col {
  grid-column-end: span 1;
  //flex-wrap: wrap;
  //width: 70%;
  //height: 100%;
  //min-width: 50px;
  max-width: 2fr;
 }  //border: solid;

//#mycontainer
////background-color: red;
////overflow: auto;
//////width: 100%;

//#myinner
////overflow: hide;
////width: 90%;

//.mychild
////float: left;
////background-color: blue;
////width: 100;
//////height: 150px;
